export const moreWork = [
    {
        title: 'I followed advice for making friends and learned what it takes',
        source: 'Slate',
        role: 'Writer',
        url: 'https://slate.com/advice/2024/09/how-to-make-friends-adult-common-advice-moving.html'
    },
    {
        title: 'Saying goodbye to my childhood friend Arthur',
        source: 'The Atlantic',
        role: 'Writer',
        url: 'https://www.theatlantic.com/ideas/archive/2022/02/arthur-pbs-kids-series-finale/622839/'
    },
    {
        title: "Running a virtual kitchen restores my friends' spirits",
        source: 'VICE',
        role: 'Writer',
        url: 'https://www.vice.com/en/article/xwnn8n/running-a-virtual-kitchen-in-overcooked-restores-my-friends-spirits'
    },
    {
        title: "Final Fantasy taught me how to be a friend",
        source: 'The Outline',
        role: 'Writer',
        url: 'https://theoutline.com/post/780/final-fantasy-taught-me-how-to-be-a-friend'
    },
    {
        title: 'How Myha’la Herrold succeeds in showbiz',
        source: 'SSENSE',
        role: 'Writer',
        url: 'https://www.ssense.com/en-us/editorial/culture/myhala-herrold-interview-industry-season-two'
    },
    {
        title: 'A Jamaican-British gay man makes his own way',
        source: 'The New York Times',
        role: 'Writer',
        url: 'https://www.nytimes.com/2021/06/08/books/review/paul-mendez-rainbow-milk.html'
    },
    {
        title: 'My grandfather rests on a hill that leads to heaven',
        source: 'BuzzFeed News',
        role: 'Writer',
        url: 'https://www.buzzfeednews.com/article/aaronedwards/my-grandfather-rests-on-a-hill-that-leads-to-heaven'
    },
    {
        title: "Queer Chronicles",
        source: 'iHeart',
        role: 'Story Editor',
        url: 'https://www.queerchronicles.com/'
    },
    {
        title: "Not Past It",
        source: 'Gimlet',
        role: 'Story Editor',
        url: 'https://gimletmedia.com/shows/not-past-it'
    },
    {
        title: "Reply All",
        source: 'Gimlet',
        role: 'Story Editor',
        url: 'https://gimletmedia.com/shows/reply-all'
    },
    {
        title: "Going Through It",
        source: 'Pineapple',
        role: 'Story Editor',
        url: 'https://podcasts.apple.com/us/podcast/going-through-it/id1458893934'
    },
    {
        title: "The Invisible Seam",
        source: 'Pineapple',
        role: 'Story Editor',
        url: 'https://podcasts.apple.com/us/podcast/the-invisible-seam/id1618279160'
    },
    {
        title: "Planet Money",
        source: 'NPR',
        role: 'Story Editor',
        url: 'https://www.npr.org/2021/06/02/1002538572/black-wall-street'
    },
    {
        title: "The Outline World Dispatch",
        source: 'The Outline',
        role: 'Host',
        url: 'https://theoutline.com/post/3596/monopoly-new-rules-world-dispatch'
    },
    {
        title: "Pop-Up Magazine: At Home",
        source: 'Pop-Up Magazine',
        role: 'Producer, Director',
        url: 'https://www.youtube.com/watch?v=r2NCjIV-E2E'
    },
    {
        title: "Pop-Up Magazine: The Fall Issue",
        source: 'Pop-Up Magazine',
        role: 'Producer, Director',
        url: 'https://www.popupmagazine.com/watch/'
    },
    {
        title: "Topic Talks: Music",
        source: 'Topic Studios',
        role: 'Producer, Director',
        url: 'https://nyuskirball.org/events/topic-talks-music/'
    },
];
